export function loadJSON(file, callback) {
  fetch(file
    ,{
      headers : {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }
  )
    .then(response => {
      return response.json();
    })
    .then(data => {
      data.forEach(item => {
        Object.keys(item).forEach(key => {
          if (item.hasOwnProperty(key)){
            if (Array.isArray(item[key])){
              item[key] = item[key].join("");
            }
          }
        })
      })

      callback(data);
    });
}