import React from "react";

export default class OSSLink extends React.Component {
  render() {
    const {description, link, title} = this.props;

    return (
      <div className="oss-link">
        <a href={link} target="_blank" rel="noreferrer">{title}</a>
        <p dangerouslySetInnerHTML={{__html: description}}/>
      </div>
    );
  }
}
