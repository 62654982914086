import React from "react";

import OSSLink from "./OSSLink";
import {loadJSON} from "../../common/common";

export default class Home extends React.Component {
  constructor(props) {
    super(props);

    this.defaultState = {oss_links: []};
    this.state = this.defaultState;
  }

  componentDidMount() {
    loadJSON('/oss_links.json', data => {
      this.setState({oss_links: data});
    })
  }

  render() {
    const title = "Open Source Software Links";
    let key = 0;

    return (
      <div className="page">
        <h4>{title}</h4>
        <p>Here are the links to the source code for my Open Source Software projects.
          Although my code is open source, some of the 3rd party libraries may have different
          licensing requirements.
        </p>
        <p>If available, prebuilt versions can be found in the downloads folder. The source for Frac2lz is not
          yet available but will be soon.</p>
        {this.state.oss_links.map(
          link => <OSSLink
            description={link.description}
            link={link.link}
            title={link.title}
            key={key++}
          />
        )}
      </div>
    );
  }
}
