import './nav.scss'
import React from 'react';

class Nav extends React.Component {
  render(){
	return (
		<div className="nav_component">
		  <nav>
			<ul>
			  <li><a  href="/">Home</a></li>
			  <li><a href="/downloads">Downloads</a></li>
			</ul>
		  </nav>
		</div>
	);
  }
}

export default Nav;
