import React from "react";

import './App.scss';
import Header from "./components/Header";
import Home from "./views/home/Home";

export default class App extends React.Component {
  render() {
    return (
      <div className="container">
        <div>
          <main className="row">
            <Header/>
            <Home/>
          </main>
          {/*<Footer/>*/
          }
        </div>
      </div>
    )
  }
}
