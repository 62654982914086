import './header.scss'
import Nav from "./Nav";

function Header(){
  return(
    <header className="row">
      <Nav className="col l6 m8 s12 offset-l3 offset-m2"/>
    </header>
  )
}

export default Header